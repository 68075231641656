<template lang="">
    <b-card class="st-section st-section__summary mt-6">
        <div class="st-section-header">
            <i v-if="isValid || isDefault" :class="['fas fa-check-circle', {'text-success': isValid}]"></i>
            <i v-else class="fas fa-check-circle text-dark-light"></i>
            <span>{{ $t('APPLICATION.SUMMARY.DETAILS') }}</span>
        </div>
        <div class="st-section-body">
            <div class="cart">
                <div class="cart__item" v-for="(tax, index) in applicationTaxes" :key="`tax-${index}`">
                    <div>1 x {{ `${$t('APPLICATION.SUMMARY.TAX_TEXT')} ${tax.description} (${tax.uat_name})` }}</div>
                    <div>{{ `${tax.value} ${$t('GENERAL.CURRENCY_LABEL')}` }}</div>
                </div>
                <div class="cart__item" v-for="(tax, index) in signingTaxes" :key="`sign-${index}`">
                    <div>1 x {{ $t('APPLICATION.SUMMARY.PAY_AS_YOU_GO_SUMMARY_TEXT')  }}</div>
                    <div>{{ `${tax.value} ${$t('GENERAL.CURRENCY_LABEL')}` }}</div>
                </div>
                
            </div>
            <div class="cart-total">
                <div>{{ $t('APPLICATION.SUMMARY.TOTAL_TAXES') }}</div>
                <div>{{ `${totalApplicationTaxes} ${this.$t('GENERAL.CURRENCY_LABEL')}` }}</div>
            </div>
            <div class="cart-total" v-if="signDetails.isOnline">
                <div class="cart-total__name">{{ $t('APPLICATION.SUMMARY.CERTIFICATE_TOTAL_TAXES') }}</div>
                <div class="cart-total__price">{{ `${totalSigningTaxes} ${this.$t('GENERAL.CURRENCY_LABEL')}` }}</div>
            </div>
        </div>
    </b-card>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ApplicationPaymentSummary',
    props: ['formModels', 'signDetails', 'taxCategoryName'],
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            record: "applications/form/record",
            applicationTaxes: "applications/form/applicationTaxes",
            signingTaxes: "applications/form/signingTaxes",
            totalApplicationTaxes: "applications/form/totalApplicationTaxes",
            totalSigningTaxes: "applications/form/totalSigningTaxes",
        }),
        isDefault() {
            return !this.record;
        },
        isValid() {
            return !!this.record?.is_paid;
        },
    },
}
</script>
